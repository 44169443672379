import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.css'],
    standalone: false
})
export class PublicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
